<template>
    <div style="text-align: left;">
        <el-card>
            <div class="top">
                <div class="top_left">
                    <el-button-group>
                        <el-button size="mini" :type="ticketIsPublish===null&&isEffective===null?'primary':''" @click="initList()">全部</el-button>
                        <el-button size="mini" :type="ticketIsPublish===false?'primary':''" @click="setTicketIsPublish(false)">未开放领取</el-button>
                        <el-button size="mini" :type="ticketIsPublish===true?'primary':''" @click="setTicketIsPublish(true)">已开放领取</el-button>
                        <el-button size="mini" :type="isEffective===false?'primary':''" @click="setIsEffective(false)">未失效</el-button>
                        <el-button size="mini" :type="isEffective===true?'primary':''" @click="setIsEffective(true)">已失效</el-button>
                    </el-button-group>
                </div>
                <div class="top_Right">
                    <el-button size="mini" plain icon="el-icon-plus" @click="$oucy.go('/enter/CouponCreate')">创建优惠券</el-button>
                </div>
            </div>
            <div class="m-t-15">
                <div class="items" v-if="ticketByEnterprise && ticketByEnterprise.length">
                    <div class="item" v-for="(v,i) in ticketByEnterprise" :key="i">
                        <div class="box">
                            <coupon :v="v"></coupon>
                            <div class="item_bottom">
                                <el-button size="mini" plain type="primary" @click.stop="deleteTicket(v,i)">删除</el-button>
                                <el-button size="mini" plain @click.stop="openUpdateTicket(v,i)" v-if="!v.isDead">修改</el-button>
                                <el-button size="mini" :type="v.ticketIsPublish?'danger':'primary'" @click.stop="release(v,i)" v-if="!v.isDead">{{v.ticketIsPublish?'取消开放':'开放领取'}}</el-button>
                                <el-popover placement="bottom" width="350" trigger="click">
                                    <div>
                                        <div class="df-jc-s-b top">
                                            <div>
                                                <div class="c-10 f16">{{v.ticketGetNum}}</div>
                                                <div class="c-2 f12">已领券人数</div>
                                            </div>
                                            <div>
                                                <div class="c-10 f16">{{v.ticketGetUsedNum}}</div>
                                                <div class="c-2 f12">已用券人数</div>
                                            </div>
                                            <div>
                                                <div class="c-10 f16">{{v.ticketGetNoUsedNum}}</div>
                                                <div class="c-2 f12">未用券人数</div>
                                            </div>
                                        </div>
                                        <div v-if="v.ticketGetList && v.ticketGetList.length">
                                            <div class="bold f16 m-t-10">领券用户列表</div>
                                            <div style="overflow: auto;max-height: 350px;">
                                                <div class="df-jc-s-b df-ai-c p-t-25" v-for="(vv,ii) of v.ticketGetList" :key="ii">
                                                    <div class="left">
                                                        <img class="head" :src="$oucy.ossUrl+vv.getUserauthAvata" alt="">
                                                    </div>
                                                    <div class="center">
                                                        <div class="bold f16 line-1">{{vv.getUserauthNicknane}}</div>
                                                        <div class="c-2">领券日期 <span>{{vv.getTime}}</span></div>
                                                    </div>
                                                    <div class="right p-l-10" :class="[!vv.ticketUsed?'c-10':'']">{{!vv.ticketUsed?'未':'已'}}用券</div>
                                                </div>
                                                <div @click="queryTicketGetList(v,i)" v-if="v.hasMore && v.start>0" class="hasMore">点击看更多</div>
                                            </div>
                                        </div>
                                    </div>
                                    <el-button size="mini" class="m-l-10" slot="reference" @click.stop="openTicketGetList(v,i)">查看使用</el-button>
                                </el-popover>
                                <el-button size="mini" class="m-l-10" plain @click.stop="openDialogFormVisible(v,i)" v-if="!v.isDead">赠送</el-button>
                            </div>
                        </div>
                    </div>
                </div>
                <div v-else>
                    <div class="notData" v-if="hasAjax==false">---- 暂无数据 ----</div>
                </div>
            </div>
        </el-card>
        <el-dialog width="500px" :title="form.title" :visible.sync="distributionVisible">
            <el-form ref="form" :rules="rules" :model="form" label-width="20px">
                <el-form-item label="" prop="ticketPubTotal">
                    <div> <span class="bold f18">增加券库存量</span></div>
                    <div class="c-2">当前券余量：{{form.min}}</div>
                    <el-input-number v-model="form.ticketPubTotal" :min="0" :max="100000" label="描述文字"></el-input-number><span class="m-l-10 c-2">改后券余量: <span style="color:#2090FF;">{{form.ticketPubTotal+form.min}}</span></span>
                </el-form-item>
                <el-form-item label="" prop="ticketDeadTime">
                    <div> <span class="bold f18">延长有效期至</span> <span class="f16 c-2">(券有效期只能延长)</span></div>
                    <div class="c-2">当前券失效时间：{{form.old_ticketDeadTime}}</div>
                    <el-date-picker v-model="form.ticketDeadTime" :picker-options="options" value-format="yyyy-MM-dd HH:mm:ss" type="datetime" placeholder="选择日期时间">
                    </el-date-picker>
                </el-form-item>
                <div class="text-right">
                    <el-button type="primary" @click="onSubmit">保 存</el-button>
                    <el-button @click="distributionVisible = false">取 消</el-button>
                </div>
            </el-form>
        </el-dialog>
        <selectUserDialog ref="selectUserDialog" @callBack="callBack" type='checkbox' :maxlength="maxlength" />
        <el-dialog width="500px" title="赠送优惠券" :visible.sync="distributionVisible2">
            <el-form ref="form" :rules="rules" :model="form" label-width="20px">
                <el-form-item label="" prop="ticketPubTotal">
                    <div> <span class="bold f18">赠送优惠券数量</span></div>
                    <div class="c-2">当前已选：{{userIdList && userIdList.length}}个用户</div>
                    <el-input-number v-model="ticketNum" :min="1" :max="max" label="描述文字"></el-input-number><span class="m-l-10 c-2">每人最多可赠: <span style="color:#2090FF;">{{max}}</span>张</span>
                </el-form-item>
                <div class="text-right">
                    <el-button type="primary" @click="batchGiveTickets">赠 送</el-button>
                    <el-button @click="distributionVisible2 = false">取 消</el-button>
                </div>
            </el-form>
        </el-dialog>
    </div>
</template>
<script>
import oucy from "@/util/oucyUtil";

import { ticket } from "@/service"
import { localSet, localGet, localDel } from "@/store/store"
export default {
    name: "create",
    data() {
        return {
            activeName: 'second',
            enterpriseId: null,
            ticketIsPublish: null,
            isEffective: null,
            ticketByEnterprise: [],
            distributionVisible: false,
            distributionVisible2: false,
            rules: {

                ticketDeadTime: [
                    { required: true, message: '请选择失效时间', trigger: 'blur' },
                ]
            },
            form: {
                enterpriseId: null,
                ticketId: null,
                title: null,
                min: 1,
                "ticketDeadTime": "",
                "ticketPubTotal": null,
                "old_ticketDeadTime": new Date(),

            },
            options: {
                disabledDate: (time) => {
                    return time.getTime() < new Date(this.form.old_ticketDeadTime).getTime()
                }
            },
            v: {}, //缓存优惠券
            visible: false,
            maxlength: -1,
            max: -1,
            ticketNum: 1,
            userIdList: [],
            hasAjax: true,
            ticketGetList: {},
            start: 0,
        }
    },
    mounted() {
        let enterprise = localGet('enterprise')
        this.enterpriseId = enterprise.id
        this.form.enterpriseId = enterprise.id
        this.getTicketByEnterprise()
    },
    methods: {
        handleClick(tab, event) {
            console.log(tab, event);
        },
        initList() {
            this.ticketIsPublish = null
            this.isEffective = null
            this.hasAjax = true
            this.ticketByEnterprise = []
            this.getTicketByEnterprise()
        },
        setTicketIsPublish(v) {
            if (this.ticketIsPublish === v) {
                // return
                this.ticketIsPublish = null
            } else {
                this.ticketIsPublish = v
            }
            this.hasAjax = true
            this.ticketByEnterprise = []
            this.getTicketByEnterprise()
        },
        setIsEffective(v) {
            if (this.isEffective === v) {
                // return
                this.isEffective = null
            } else {
                this.isEffective = v
            }
            this.hasAjax = true
            this.ticketByEnterprise = []
            this.getTicketByEnterprise()
        },
        getTicketByEnterprise() {
            // enterpriseId    企业编码        true    
            // ticketIsPublish 是否发布 false/未发布 true/已发布     false   
            // ticketKind  优惠券类型 0满减券 1现金券
            ticket.getTicketByEnterprise({ ticketIsPublish: this.ticketIsPublish, isEffective: this.isEffective, enterpriseId: this.enterpriseId, }).then(res => {
                this.hasAjax = false
                for (let v of res) {
                    v.ticketGetList = []
                    v.hasMore = true
                    v.ticketGetNoUsedNum = 0
                    v.ticketGetNum = 0
                    v.ticketGetUsedNum = 0
                    v.start = 0
                }
                this.ticketByEnterprise = res
            })
        },
        openTicketGetList(v, i) {
            v.ticketGetList = []
            v.start = 0
            v.hasMore = true
            this.queryTicketGetNum(v,i)
            this.queryTicketGetList(v, i)
        },
        queryTicketGetList(v, i) {

            ticket.queryTicketGetList({ ticketId: v.id, start: v.start, limit: 10 }).then(res => {
                if (res.content) {
                    v.ticketGetList.push(...res.content)
                }
                v.hasMore = !res.last
                    ++v.start
            })
        },
        queryTicketGetNum(v,i){
            ticket.queryTicketGetNum({ ticketId: v.id}).then(res=>{
                v.ticketGetNoUsedNum =res.ticketGetNoUsedNum ||0
                v.ticketGetNum =res.ticketGetNum ||0
                v.ticketGetUsedNum =res.ticketGetUsedNum ||0
            })
        },
        deleteTicket(v, i) {
            this.$oucy.confirm('确定删除当前优惠券？').then(res => {
                ticket.deleteTicket({ ticketId: v.id }).then(res => {
                    this.$message('操作成功')
                    this.ticketByEnterprise.splice(i, 1)
                })
            }).catch(err => {})
        },
        release(v, i) {
            this.$oucy.confirm(`确定“${v.ticketIsPublish?'取消开放':'开放领取'}”当前优惠券？`).then(res => {
                ticket.release({ ticketId: v.id }).then(res => {
                    this.$message('操作成功')
                    v.ticketIsPublish = !v.ticketIsPublish
                })
            }).catch(err => {})
        },
        batchGiveTickets(v, i) {
            this.$oucy.confirm(`确定赠送当前优惠券？`).then(res => {
                let data = {
                    ticketIdList: JSON.stringify(this.$oucy.getSrcList([this.v], 'id')),
                    ticketNum: this.ticketNum,
                    userIdList: JSON.stringify(this.$oucy.getSrcList(this.userIdList, 'id')),
                }
                ticket.batchGiveTickets(data).then(res => {
                    this.$message('操作成功')
                    this.distributionVisible2=false
                    v.ticketGetTotal+=this.ticketNum
                })
            }).catch(err => {})
        },
        openUpdateTicket(v, i) {
            this.v = v
            this.form.title = '修改:' + v.ticketName
            this.form.ticketPubTotal = 1
            this.form.ticketDeadTime = v.ticketDeadTime
            this.form.min = v.ticketPubTotal * 1 - v.ticketGetTotal * 1
            this.form.ticketId = v.id
            this.form.old_ticketDeadTime = v.ticketDeadTime
            this.distributionVisible = true
        },
        onSubmit() {
            this.$refs['form'].validate((valid) => {
                if (valid) {
                    this.form.ticketPubTotal = this.form.ticketPubTotal * 1
                    this.updateTicket()
                } else {
                    console.log('error submit!!');
                    return false;
                }
            })
        },
        updateTicket() {
            this.$oucy.confirm(`确定${this.form.title}？`).then(res => {
                ticket.updateTicket(this.form).then(res => {
                    this.v.ticketPubTotal = this.form.ticketPubTotal + this.v.ticketPubTotal
                    this.v.ticketDeadTime = this.form.ticketDeadTime
                    this.distributionVisible = false
                })
            }).catch(err => {})
        },
        // 打开选择用户
        openDialogFormVisible(v, i) {
            this.v = v
            this.maxlength = v.ticketPubTotal * 1 - v.ticketGetTotal * 1
            if(this.maxlength>0){
                this.$refs.selectUserDialog.openDialogFormVisible()
            }else{
                this.$alert('已经赠送完'+v.ticketPubTotal+'张优惠券，如需赠送请先修改增加券库存量')
            }
        },
        // 选择用户回调
        callBack(list) {
            this.distributionVisible2 = true
            this.userIdList = list
            if (list.length > 1) {
                let names = ''
                for (let v of list) {
                    names += v.ltUserDetailFull.detailNickname + '；'
                }
                names += list.length + '位用户。'
            }
            this.max = ~~(this.maxlength / list.length)
            // this.enterApplyForm.userAuthId = v.id
            // this.selectObj = v
        },
    }
}
</script>
<style scoped lang="less">
.top {
    display: flex;
    justify-content: space-between;
}

.top_Right {
    display: flex;
    align-items: flex-start;
}

.top_Right_box {
    border-radius: 10px;
    padding: 22px 32px;
    cursor: pointer;
}

.top_Right_box+.top_Right_box {
    margin-left: 10px;
}

.top_Right_box_title {
    font-size: 16px;
    font-family: Microsoft YaHei;
    font-weight: bold;
}

.top_Right_box1 {
    background: #EAF3FF;

    .top_Right_box_title {
        color: #555C68;
    }
}

.top_Right_box2 {
    background: #FFF7EA;

    .top_Right_box_title {
        color: #E7801B;
    }
}

.top_Right_box3 {
    background: #FFF4F4;

    .top_Right_box_title {
        color: #F05353;
    }
}

.top_Right_box_info {
    font-size: 12px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #666666;
    margin-top: 10px;
}

.items {

    display: flex;
    flex-wrap: wrap;
    margin-left: -15px;
    margin-right: -15px;
}

@media screen and (max-width: 1920px) {
    .item {
        width: 25%;
    }
}

@media screen and (max-width: 1600px) {
    .item {
        width: 33.333%;
    }
}

.item {
    box-sizing: border-box;
    padding:0 15px;
    margin-bottom: 50px;
}

.item_bottom {
    text-align: center;
    margin-top: 20px;
    /*padding-bottom: 30px;*/
}


.head {
    width: 50px;
    height: 50px;
    border-radius: 50%;
}

.center {
    flex: 1;
    margin-left: 5px;
}

.top {
    text-align: center;
}

.c-10 {
    color: #2090FF;
}

.notData {
    text-align: center;
    color: #ccc;
    padding: 125px 0;
    font-size: 14px;
}

.hasMore {
    cursor: pointer;
    text-align: center;
    color: #999;
    padding: 15px;
}
</style>